import { Link } from '@remix-run/react'

type Navigation = {
  main: ({ name: string; to: string } | { name: string; href: string })[]
}
const footerNavigation: Navigation = {
  main: [
    { name: 'Home', to: '/' },
    { name: 'Blog', to: '/blog' },
    { name: 'Join', to: '/auth/join' },
    { name: 'Login', to: '/auth/login' },
  ],
}

export default function Footer() {
  return (
    <footer className="mt-24 bg-neutral-900 sm:mt-12">
      <div className="max-w-md px-4 py-12 mx-auto overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <nav
          className="flex flex-wrap justify-center -mx-5 -my-2"
          aria-label="Footer"
        >
          {footerNavigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              {'href' in item ? (
                <a
                  href={item.href}
                  className="text-base text-neutral-400 hover:text-neutral-300"
                >
                  {item.name}
                </a>
              ) : (
                <Link
                  to={item.to}
                  className="text-base text-neutral-400 hover:text-neutral-300"
                >
                  {item.name}
                </Link>
              )}
            </div>
          ))}
        </nav>
        <p className="mt-8 text-base text-center text-neutral-400">
          &copy; 2023 Snipbot. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
