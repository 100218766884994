import { Fragment } from 'react'

import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink } from '@remix-run/react'

import { classNames } from '~/utils'

import Logo from './svg/logo'

const navigation = [
  { name: 'Job Alerts', to: '/products/signals' },
  { name: 'Email Reminders', to: '/products/remind' },
  { name: 'Blog', to: '/blog' },
]

export function Header() {
  return (
    <Popover as="header" className="relative">
      <div className="bg-brand-off-white">
        <nav
          className="relative flex items-center justify-between px-6 pt-6 mx-auto max-w-7xl xl:px-8"
          aria-label="Global"
        >
          <div className="flex items-center flex-1 border-b border-solid pb-3">
            <div className="flex items-center justify-between w-full lg:w-auto lg:mr-16">
              <a href="/">
                <span className="sr-only">Snipbot</span>
                <Logo
                  className="w-auto h-12 text-orange-500"
                  alt="Snipbot Logo"
                />
              </a>
              <div className="flex items-center mb-1 -mr-2 lg:hidden">
                <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md bg-neutral-800 hover:bg-neutral-900 text-neutral-100 00 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-orange-500">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="hidden space-x-10 lg:flex lg:ml-10">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.to}
                  className={({ isActive }) =>
                    classNames(
                      'text-base text-neutral-600 hover:text-neutral-900',
                      isActive ? 'font-semibold' : 'font-medium'
                    )
                  }
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
          {/* <div className="hidden lg:flex lg:items-center lg:space-x-6">
            <Link
              to="/auth/join"
              className="flex items-center justify-center w-full px-6 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow bg-neutral-800 hover:bg-neutral-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-orange-500"
            >
              Join
            </Link>
          </div> */}
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-30 p-2 transition origin-top transform lg:hidden"
        >
          <div className="overflow-hidden rounded-lg shadow-lg bg-neutral-50 ring-1 ring-black ring-opacity-5">
            <div className="flex items-center justify-between px-5 pt-4">
              <div>
                <Logo
                  className="w-auto h-8 text-orange-500"
                  alt="Snipbot Logo"
                />
              </div>
              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center p-2 bg-white rounded-md text-neutral-400 hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="pt-5 pb-6">
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    className="block px-3 py-2 text-base font-medium rounded-md text-neutral-900 hover:bg-neutral-50"
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
              {/* <div className="px-5 mt-6">
                <Link
                  to="/auth/join"
                  className="block w-full px-4 py-2 font-medium text-center text-white bg-orange-500 border border-transparent rounded-md shadow hover:bg-orange-600"
                >
                  Join
                </Link>
              </div> */}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
